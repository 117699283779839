import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './App.css';

function App() {


	const audioPlayerRef = React.useRef<AudioPlayer>(null);

	const audioPaths = [
		{ location: 'audio/machtHochDieTuer.mp3', title: 'Macht hoch die Tür' },
		{ location: 'audio/tochterZion.mp3', title: 'Tochter Zion'},
		{ location: 'audio/ohDuFroehliche.mp3', title: 'Oh du fröhliche'},
		{ location: 'audio/angelsCarol.mp3', title: 'Angels\' Carol' },
		{ location: 'audio/hebeDeineAugenAuf.mp3', title: 'Hebe deine Augen auf' },
		{ location: 'audio/ohDuStilleZeit.mp3', title: 'Oh, du stille Zeit' },
		{ location: 'audio/stillStillStill.mp3', title: 'Still, still, still' },
		{ location: 'audio/esWirdSchoGleiDumpa.mp3', title: 'Es wird scho glei dumpa' },
		{ location: 'audio/andachtsjodler.mp3', title: 'Andachtsjodler' }
	];

	const [ isPlaying, setIsPlaying ] = React.useState(false);

	const [ audioIndex, setAudioIndex ] = React.useState(-1);
	return (
		<div className='App'>
			<img src='Cover.png' className='cover'/>
			<div className='audioElements'>
				{ audioPaths.map((audioPath, index) =>
					<div className={ 'audioElement ' + (audioIndex === index ? 'active' : '') } key={ index } onClick={ () => {
						setIsPlaying(true);
						setAudioIndex(index);
						audioPlayerRef.current?.audio.current?.play();
					} }>
						{ index + 1 }.{ '\t' }&nbsp;&nbsp;{ audioPath.title }
						{ audioIndex === index && isPlaying && <div className='lds-facebook'>
							<div></div>
							<div></div>
							<div></div>
						</div> }
					</div>)
				}
			</div>
			{ audioIndex > -1 && <AudioPlayer
				autoPlayAfterSrcChange
				autoPlay
				src={ audioPaths[audioIndex].location }
				onClickNext={ e => {
					setAudioIndex((audioIndex + 1) % audioPaths.length);
				} }
				onClickPrevious={ e => {
					setAudioIndex((audioPaths.length + audioIndex - 1) % audioPaths.length);
				} }
				showSkipControls={ true }
				showJumpControls={ false }
				onEnded={ e => {
					setAudioIndex((audioIndex + 1) % audioPaths.length);
				} }
				onPlay={ e => {
					setIsPlaying(true);
				} }
				onPause={ e => {
					setIsPlaying(false);
				} }
				onAbort={ e => {
					setIsPlaying(false);
				} }
				onError={ e => {
					setIsPlaying(false);
				} }
				ref={ audioPlayerRef }
			/> }
		</div>
	);
}

export default App;
